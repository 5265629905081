@import "../assets/scss/index";
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: auto !important;
}

button:focus {
  outline: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.designer-container {
  min-height: 650px;
  height: 100vh;
  //overflow: hidden;
}

.text-form-content {
  padding: 0 20px;

  button {
    width: 100%;
    border: 1px solid $btn-border-col;
    border-radius: 2px;
    background-color: transparent;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    text-align: left;
    padding: 0 10px;
    cursor: pointer;

    .text-form-button-label {
      margin-left: 10px;
    }
  }
}

//.text-form-popup-overlay {
//  position: fixed;
//  top: 0;
//  left: 0;
//  background-color: $black;
//  opacity: 0.2;
//  z-index: 3;
//  width: 100%;
//  height: 100%
//}

.text-form-popup-overlay--transparent {
  background-color: transparent;
}

.designer-container .text-form-popup-content .designer-tools-uploader {
  max-height: 100%;
}

.text-form-popup-content-inner {
  position: fixed;
  background-color: $white;
  overflow: auto;
  z-index: 999;
  margin: auto;
  display: block;
  padding: 10px;
  box-sizing: border-box;
  left: 0;
  //width: calc(100% - 30px);
  width: 100%;
  top: 0;
  height: 100vh;

  &.faceCropLoading {
    padding: 0;
  }

  &.orderPreviewPopup {
    display: inline-block;
  }

  @include media(">tablet") {
    padding: 20px;
  }
  @include media("<tablet") {
    .faceCropLoading {
      padding: 0;
    }
  }

  &.faceCropMultifaceWrap {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    padding: 10px 20px;
    //@include media("<tablet") {
    //  height: calc(100vh - 30px);
    //}
  }

  .text-form-popup-content-title {
    text-align: center;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
    color: #333333;
    @include media(">tablet") {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }

  textarea {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    resize: vertical;
    border: 2px solid rgba(#03a786, 0.5);
    border-radius: 10px;
    max-width: 616px;
    margin: 25px auto;
    display: block;
    @include media("<wide") {
      font-size: 16px;
    }

    &:focus {
      border: 2px solid #03a786;
      outline: none;
    }
  }

  .text-form-popup-content-buttons {
    text-align: center;
    margin-top: 9px;
    @include media(">wide") {
      text-align: center;
    }

    button {
      margin-left: 10px;
    }
  }

  .canvas-crop-popup .konvajs-content {
    border: none;
    margin: 0 auto;
  }
}

.designer-container .text-form-popup-content-inner .designer-tools-uploader {
  background: $white;
}

.text-form-popup-content-inner__imgCrop {
  .konvajs-content {
    margin: 0 auto;
  }
}

.orderPreviewPopup__wrap {
  @include media(">=tablet") {
    display: flex;
  }

  .conf-preview-check-label {
    font-size: 14px;
    line-height: 1.8;
    @include media(">=desktop") {
      font-size: 20px;
    }
  }
}

.orderPreviewPopup__checkboxWrap {
  max-width: 700px;
  margin: 0 auto;
  padding: 30px 30px;
  @include media(">tablet") {
    padding: 140px 30px;
  }
}

.text-form-popup-content-title__wrap {
  display: flex;
  justify-content: space-between;

  margin-bottom: 10px;

  img {
    max-width: 100%;
  }

  .text-form-popup-back {
    max-width: 20px;
    cursor: pointer;
  }

  .text-form-popup-content-title {
    font-weight: 600;
    letter-spacing: 1px;
    color: #333333;
  }

  .text-form-popup-close {
    max-width: 20px;
    cursor: pointer;
    @include media(">tablet") {
      max-width: 50px;
      padding-right: 30px;
    }
  }
}

.popup-inner {
  .uploaded-files img {
    margin: 0 5px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 1px 4px 0 rgba($black, 0.5);
    }
  }

  .text-form-popup-content-title span {
    cursor: pointer;
    text-decoration: underline;
  }
}

.faceCropMultifaceTitle {
  font-weight: bold;
  margin-bottom: 15px;
}

.faceCropMultiface {
  text-align: center;

  .btn-outline-secondary {
    border: none;
    font-size: 16px;
    color: $white;
    background: #adadad;
    border-radius: 100px;
    padding: 15px;
    margin-left: 0;
  }

  .btn-secondary {
    margin-left: 15px;
    border: none;
    padding: 15px;
    font-weight: 600;
    font-size: 16px;
    color: $white;

    background: #333333;
    border-radius: 100px;
  }
}

.text-form-popup-content-inner
  .text-form-popup-content-buttons.faceCropMultiface-buttons,
.text-form-popup-content-inner
  .text-form-popup-content-buttons.textareaPopupEditText {
  text-align: center;

  .btn-outline-secondary {
    border: none;
    font-weight: 600;
    color: $white;
    background: #adadad;
    border-radius: 100px;
    margin-left: 0;
    padding: 16px;
    font-size: 16px;
  }

  .btn-secondary {
    margin-left: 15px;
    border: none;
    font-weight: 600;
    padding: 16px;
    font-size: 16px;
    color: $white;

    background: #333333;
    border-radius: 100px;
  }
}

.text-form-popup-content-inner
  .text-form-popup-content-buttons.textareaPopupEditText {
  button {
    margin: 10px 15px;
    min-width: 100px;
  }
}

.faceCropMultifacePreview {
  flex-direction: column;
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media("<=tablet") {
    max-width: 270px;
  }

  img {
    position: absolute;
    width: 100%;
    border-radius: 300px;
    max-width: 59px;
    margin-bottom: 20px;
    @include media("<=tablet") {
      left: 0;
      top: 0;
      max-width: 22px;
    }
  }

  img:first-child {
    position: static;
    width: 100%;
    max-width: 700px;
    border-radius: 0;
    @include media("<=tablet") {
      max-width: 270px;
    }
  }
}

.faceCropMultifacePreview__status {
  text-decoration: underline;
  font-size: 20px;
}

.faceCropMultifaceOverlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $white;
  z-index: 3;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 100%;
    max-width: 300px;
    max-height: 300px;
  }
}

.property-option {
  margin-top: 15px;
  overflow: hidden;
  border-bottom: 1px solid $border-col;
  padding-bottom: 10px;

  .property-label {
    font-weight: bold;
    float: left;
    width: 50%;
    font-size: 14px;
    line-height: 25px;
  }

  .property-value {
    font-weight: bold;
    float: left;
    width: 50%;
  }

  .property-value select {
    width: 100%;
    padding: 5px;
  }
}

.cancel-button {
  background-color: transparent;
  color: #919191;
  border: 1px solid $btn-border-col !important;
}

.ok-button {
  background-color: $primary;
  color: #fff;
  border: 1px solid $primary !important;
}

.secondary-button {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 600;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  @include media(">tablet") {
    margin-right: 15px;
    font-size: 16px;
  }
}

.canvas-status {
  margin-left: 20px;
  color: #999;
}

.designer-block {
  min-width: 100vw;
  min-height: calc(100vh - 281px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column-reverse;
  @include media("<wide") {
    position: initial;
  }

  @include media(">wide") {
    flex-direction: row-reverse;
    min-height: calc(100vh - 81px);
    align-items: flex-start;
    justify-content: center;
  }
}

.designer-admin-mode {
  justify-content: flex-start;
  flex-direction: row;
}

.designer-content {
  display: flex;
  flex-direction: column;
  position: relative;
  //max-width: 1200px;
  margin: 0 auto;
  background-color: $white;
  //padding-bottom: 200px;
  @include media(">wide") {
    flex-direction: row;
    margin-top: 14px;
    min-height: calc(100vh - 95px);
    padding-bottom: 0;
  }
}

.crop-img-wrap {
  width: 100%;
  max-width: 802px;
  height: 100%;
  max-height: 802px;
}

//.text-form-popup-content-inner .text-form-popup-content-buttons button {
//  margin-left: 5px;
//  cursor: pointer;
//  width: 100px;
//  text-align: center;
//  border-radius: 2px;
//  border: 0;
//  height: 30px;
//}

.designer-container .designer-tools-uploader {
  width: 100%;
  background-color: $main-bg;
  height: 180px;
  overflow: auto;
  padding: 10px;
  @include media(">wide") {
    height: 510px;
    padding-bottom: 20px;
    border-radius: 0;
    z-index: 1;
    top: 0;
    position: static;
  }

  .designer-tools-btn {
    @include media(">wide") {
      display: none;
    }
  }
}

.text-form-popup-content-inner {
  .designer-tools-uploader {
    position: static;
    box-shadow: none;
    border: none;
    height: auto;
    @include media(">wide") {
      width: auto;
    }
  }
}

.ProgressWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.CheckIcon {
  color: #82c91e !important;
}

.UploadButton {
  display: none;
}

.quick-edit-label-field {
  margin-bottom: 15px;
}

.quick-edit-label-field-label {
  margin-bottom: 5px;
  font-size: 12px;
}

.quick-edit-label-field-input input {
  width: 100%;
  //padding: 10px;
}

.text-form-popup-content-store-values {
  text-align: left;
}

.designer-tools-backgrounds-list {
  text-align: center;
  padding: 10px;
}

.designer-tools-backgrounds-list-colors {
  .btn {
    margin: 6px 5px;
    padding: 23px;
    border: none;
    @include media(">tablet") {
      margin: 11px 15px;
      padding: 40px;
    }
  }

  padding-bottom: 25px;
}

.designer-tools-backgrounds-list-images {
  display: flex;
  flex-wrap: wrap;

  img {
    max-height: 68px;
    max-width: 68px;
    margin: 1px 5px;
  }

  padding-bottom: 25px;
}

.designer-tools-backgrounds-list img {
  cursor: pointer;
  margin: 5px;
}

.designer-tools-backgrounds-list__popup img {
  width: 100%;
  max-width: 250px;
}

.conf-preview-check-label {
  font-size: 12px;
  margin-bottom: 35px;
  @include media(">=desktop") {
    margin-bottom: 100px;
  }
}

.config-preview-checkbox {
  line-height: 0.9;
  margin: 16px 0;
  position: relative;
}

.config_preview_check_8409 {
  // display: none;
}

.config-preview-checkbox input[type="checkbox"] + label::before {
  content: "";
  margin-top: 0;
  top: 0.45rem;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #becad6;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  box-shadow: none;
  border-radius: 2px;
  background-image: none;
  display: block;
  position: absolute;
}

.config_preview_check_8409[type="radio"] + label,
.config_preview_check_8409[type="checkbox"] + label {
  position: relative;
  padding-left: 29px;
  color: #6f6f6f;
  font-style: normal;
  font-family: "Montserrat";
  letter-spacing: 0;
  display: block;
  text-align: left;
}

.config-preview-checkbox input[type="checkbox"]:checked + label::before {
  background-color: #007bff !important;
  border-color: transparent;
}

.config-preview-checkbox input[type="checkbox"]:checked + label::after {
  opacity: 1;
  -webkit-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  background-image: none;
}

.config-preview-checkbox input[type="checkbox"] + label::after {
  content: "";
  position: absolute;
  top: 8px;
  left: 7px;
  width: 3px;
  height: 9px;
  opacity: 0;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transition: border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    -webkit-transform 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  transition: transform 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  transition: transform 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    -webkit-transform 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  transition-delay: 0s, 0s, 0s;
  transition-delay: 0s, 0s, 0s;
  transition-delay: 0.1s;
}

.bgChangePopup {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  @include media(">wide") {
    flex-direction: row;
  }
  .designer-tools-backgrounds-list__popup {
    flex: auto;
  }
  .background-image {
    max-height: 170px;
    width: auto;
    max-width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  .bgChangePopup__preview {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: 0 auto;
      max-width: 65%;
      @include media(">wide") {
        max-width: 660px;
      }
    }

    margin-bottom: 25px;
    @include media(">wide") {
      max-width: 660px;
      margin-bottom: 0;
    }
  }
}

.textChangePopup {
  > div {
    height: auto;
    width: 100%;
  }
  width: 100%;
  max-width: 550px;

  @include media("<wide") {
    margin: 0 auto;
  }
}


.config-p.review-checkbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
    input {
    width: 20px;
    margin: 4px 0 0 0;
  }
   >label {
    width: calc(100% - 25px);
    box-sizing: border-box;
    padding-left: 10px;
  }
   button{
    margin: 0 10px;
  }
}


.orderPreviewPopup__wrap {
.faceCropMultifacePreview {
  flex-direction: row;
  flex-wrap: wrap;
  .faceCropMultifacePreview__status{
    width: 100%;
  }
  button {
    margin: 5px;
  }
}
}

@media(min-width:1120px){
  .config-p.review-checkbox { 
      input { 
      margin: 9px 0 0 0;
    } 
  }
  
}

.bgChangePopup__preview {
  position: relative;
}
#loaderdv_id {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
// div#loaderdv_id:after {
//   position: absolute;
//   content: "";
//   width: 100%;
//   height: 100%;
//   background: #0000004f;
// }

.designer-tools-backgrounds-list__popup{
  text-align: left;
  padding-left: 4%;
}
.text-form-popup-content .text-form-popup-content-inner .bgChangePopup>div{
  width: 50%;
}
@media(min-width:1200px){
  .text-form-popup-content .text-form-popup-content-inner .bgChangePopup >.designer-tools-backgrounds-list {
    max-height: 660px;
    overflow-y: auto;
  }
}
@media(max-width:1199px){
  .text-form-popup-content .text-form-popup-content-inner .bgChangePopup >.designer-tools-backgrounds-list {
    max-height: 660px;
    overflow-y: auto;
  }
  .designer-tools-backgrounds-list__popup{
    padding-left: 10px;
  }
.bgChangePopup .designer-tools-backgrounds-list__popup img {
  width: calc(50% - 10px);
}
.text-form-popup-content .text-form-popup-content-inner .bgChangePopup>div{
  width: 100%;
}
}
@media(max-width:575px){
.bgChangePopup .designer-tools-backgrounds-list__popup img {
  width: calc(100% - 10px);
}
}.rcs-inner-container>div>div[class*="ToolsViews_textMenu"] {
    height: auto;
    overflow: initial!important;
}

// custom scroll
.rcs-custom-scroll{min-height:0;min-width:0}
.rcs-custom-scroll .rcs-outer-container{overflow:hidden}
.rcs-custom-scroll .rcs-outer-container .rcs-positioning{position:relative}
.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar{opacity:1;transition-duration:.2s}
.rcs-custom-scroll .rcs-inner-container{overflow-x:hidden;overflow-y:scroll;-webkit-overflow-scrolling:touch}
.rcs-custom-scroll .rcs-inner-container:after{content:"";position:absolute;top:0;right:0;left:0;height:0;background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, rgba(0, 0, 0, 0) 100%);pointer-events:none;transition:height .1s ease-in;will-change:height}
.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after{height:5px;transition:height .15s ease-out}
.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container{user-select:none}
.rcs-custom-scroll .rcs-custom-scrollbar{position:absolute;height:100%;width:6px;right:3px;opacity:1;z-index:1;transition:opacity .4s ease-out;padding:6px 0;box-sizing:border-box;will-change:opacity;pointer-events:none}.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl{right:auto;left:3px}
.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar{opacity:1}
.rcs-custom-scroll .rcs-custom-scroll-handle{position:absolute;width:100%;top:0}
.rcs-custom-scroll .rcs-inner-handle{height:calc(100% - 12px);margin-top:6px;background-color:#03a786;border-radius:3px}
.rcs-custom-scroll{
  padding: 0 10px;
}
.cs-review-checkbox{
  position: relative;
}
.cs-review-checkbox > input {
  position: absolute;
  left: 11px;
  margin: 0!important;
  top: 3px;
  width: 20px!important;
  height: 17px;
  opacity: 0;
  z-index: 1;
}
.config-p.review-checkbox.cs-review-checkbox > label{
  padding-left: 30px;
}
.config-p.review-checkbox.cs-review-checkbox > label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
  width: 14px;
  height: 14px;
  border: 1px solid #000;
  display: inline-block;
  border-radius: 2px;
  z-index: -1;
}
.config-p.review-checkbox.cs-review-checkbox >input:checked+  label::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 4px;
  border-width: 0 0 2px 2px;
  border-color: #000;
  border-style: solid;
  left: 2px;
  top: 12px;
  transform: rotate(-45deg);
  z-index: -1;
}

@media(min-width:1200px){
   .rcs-custom-scroll {
     height: auto!important;
   }
   .rcs-custom-scroll .rcs-inner-container{
     margin-right: 0!important;
     overflow: auto!important;
   }
}
@media(max-width:1200px){
  .config-p.review-checkbox.cs-review-checkbox > label::before{
    top: 4px;
  }
  .config-p.review-checkbox.cs-review-checkbox > input:checked + label::after{
    top: 8px;
  }
}
@media(max-width:767px){
  .designer-content .designer-block .ToolsViews_active__HC2pl .ToolsViews_elZoomMenu__1psMa img{
    max-width: 30px;
  }
}