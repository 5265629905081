@import "../../assets/scss/index";

.designer-right-panel {
  width: 100%;
  border-left: 1px solid $border-col;
  border-top: 1px solid $border-col;
  position: relative;
  height: 100%;
  @include media("<=wide") {
    display: flex;
    //justify-content: center;
  }
}

.properties-column {
  max-width: 255px;
  width: 100%;
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(48, 58, 56, 0.1), 0 8px 16px 0 rgba(40, 52, 49, 0.1);
  //padding-bottom: 280px;
  @include media(">wide") {
    margin-right: 14px;
    margin-bottom: 14px;
  }
  @include media("<=wide") {
    padding-bottom: 0;
    max-width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;

    z-index: 100;
    min-height: 74px;
    width: 100%;
    background-color: $white;
    box-shadow: 1px -4px 6px 0px rgba($black, .10);
    margin-top: 170px;
    overflow-x: scroll;
    &.active {
      //height: 35vh;
      overflow: hidden;
      margin-top: 0;
      position: relative;
      min-height: calc(100vh - 60vh);
    }
  }
}


.designer-right-panel-item__column {
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
}

.colorSwitchList {
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
  display: flex;
  //@include media("<=wide") {
  //  padding-top: 5px;
  //  max-width: 100vw;
  //  flex-wrap: initial;
  //  overflow-x: scroll;
  //  .colorSwitchBtn {
  //    width: 45px;
  //    height: 45px;
  //    border-radius: 50%;
  //    margin: 10px;
  //    padding: 20px;
  //    border: 1px solid $border-col;
  //
  //    &Active {
  //      box-shadow: 0 0 0 3px rgba(0, 123, 255, .15), 0 3px 15px rgba(0, 123, 255, .2), 0 2px 5px rgba(0, 0, 0, .1);
  //      border-color: #006fe6;
  //    }
  //  }
  //}
  .colorSwitchBtn {
    width: 25px;
    height: 25px;
    margin: 5px;
  }


}

.designer-right-panel-item__color {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}


.designer-right-panel-item__titleFlex {
  .designer-right-panel-item__titleFlex {
    padding: 0;
  }

  padding: 16px 19px;
  display: flex;
  min-height: 16px;
  align-items: center;
  min-width: 70px;
  @include media("<=wide") {
    text-align: center;
  }

  .designer-right-panel-item__trash {
    display: none;
    @include media("<=wide") {
      display: block;
    }
  }

  .text {
    font-size: 12px;
    font-weight: 600;
  }

  .svg-inline--fa, img {
    width: 18px;
    height: 18px;
    margin-right: 13px;
  }

  @include media("<=wide") {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 15px 5px;
    .svg-inline--fa, img {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

.dropdownContent {
  display: none;
  padding: 5px 10px;
  width: 100%;
  margin: 0 -5px;
  //background-color: $white;

  &Btn {
    display: none;
    @include media("<wide") {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  &List {
    display: block;
    @include media("<wide") {
      display: block;
      width: 100%;
      overflow: auto;
      max-height: 22vh;
      //background-color: $white;
      margin-bottom: 10px;
    }
  }

  &Block {
    @include media("<=wide") {
      width: 100%;
      justify-content: space-around;
      display: flex;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    &Btn {
      @include media("<=wide") {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: white;
        box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.3);
        border: none;
      }
    }
  }

  @include media("<=wide") {
    padding: 0 5px;
    overflow-y: scroll;
  }
}

.designer-right-panel-item.active .dropdownContent {
  display: block;
}

.designer-right-panel-item.active {
  @include media("<wide") {
    //height: 40vh;
  }
}

.designer-rotate-icon {
  @include media(">wide") {
    display: none;
  }
}

.designer-rotate-btns {
  //display: none;
  display: flex;
  @include media(">wide") {
    display: block;
  }
}


.designer-right-panel-items {
  @include media("<=wide") {
    display: none;
  }
}

.designer-right-panel-positions {
  display: flex;
  line-height: 1.5;
  flex-wrap: wrap;

  input {
    width: 100%;
    max-width: 90px;
    margin-left: 15px;
  }
}

.colorSwitchList__recently {
  font-size: 16px;
  line-height: 2;
  @include media("<=wide") {
    text-align: center;
    max-width: 100vw;
    .colorSwitchList {
      justify-content: center;
    }
  }
}

.custom-checkbox {
  margin-right: 10px;
}

.designer-right-panel-item__titleFlex {
  //margin-right: 10px;
  font-size: 12px !important;
  font-weight: 600;
}

.colorSwitchBtn {
  width: 20px;
  height: 20px;
  border: none;
  margin: 2px;
  padding: 0;
  cursor: pointer;

  &:hover {
    border: 2px solid #fda039;
  }
}

.designer-right-panel {
  background-color: $white;
  background-repeat: no-repeat;
  background-position: center 100px;

  @include media("<=wide") {
    height: auto; //!important;
    width: 100%;
    background-color: $white;
    max-width: 100%;
    border: none;
    &.designer-right-panel-minWidth {
      //min-width: 775px;

      &.active {
        width: 100vw;
      }
    }
  }
  @include media(">wide") {
    padding-bottom: 315px;
    overflow: auto;
  }
}

.designer-right-panel-placeholder {
  text-align: center;
  padding: 10px 20px;
  color: #bbbbbb;
  position: relative;
  line-height: 1.5;

  @include media(">wide") {
    top: 50%;
    transform: translateY(-100%);
  }
}

.designer-right-panel-window {
  width: 100%;
  background-color: $white;
  height: auto !important;
  overflow: auto;
  @include media("<=wide") {
    height: auto !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  @include media(">wide") {
    flex-direction: column;
    height: 100% !important;
  }
}

.designer-right-panel-item {
  font-weight: bold;
  border-bottom: 1px solid rgba($border-col, .7);
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  //max-width: 120px;
  width: 100%;
  @include media(">wide") {
    padding-right: 23px;
    overflow: hidden;
    max-width: 100%;
    position: relative;
    font-size: 16px;
    width: auto;
    justify-content: space-between;
  }

  &:hover {
    @include media(">wide") {
      //background: $btn-hover;
    }
  }
}

.designer-right-panel-btn {
  cursor: pointer;
  margin-left: 5px;
  width: 30px;
  border: 1px solid rgba(#A7AAA8, 1);
  background-color: $white;
  height: 30px;
  padding: 6px;
  border-radius: .35rem;

  .svg-inline--fa {
    height: 20px;
    padding-right: 0;
  }

  &.designer-right-panel-btn_size {
    transition: transform .1s;

    &:hover {
      transform: scale(1.05);
    }

    padding: 0;
    //border: none;
    //border: none;

    img {
      width: 100%;
    }
  }
}

.designer-right-panel-colorBox {
  width: 100%;
  max-width: 60px;
  height: 36px;
  box-sizing: border-box;
  position: relative;
  border: 3px solid $white;
  border-radius: .35rem;

  &:after {
    content: '';
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: .35rem;
    position: absolute;
    left: -3px;
    top: -3px;
    border: 1px solid #becad6;
    display: block;
  }

}

.designer-right-panel-inputNumber {
  padding-left: 10px;
  margin-left: 8px;

  input {
    //border: 1px solid rgba(#A7AAA8, 1);
    width: 60px;
    //height: 30px;
    font-size: 15px;
    //color: $primary;
    //padding-left: 6px;
    font-family: $font-secondary;
  }

  @include media("<=wide") {
    padding-left: 7px;
    margin-bottom: 10px;
    input {
      width: 45px;
    }
  }
}

.selected-font {
  display: none;
  @include media(">wide") {
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    flex: 1 1 100%;
    width: 100%;
    //text-align: center;
    padding-left: 10px;
    font-weight: 600;
    display: block;

  }
}

.font-family-selector {
  max-height: 123px;
  overflow: auto;
  @include media("<wide") {
    overflow: initial;
    max-height: 100%;
    div {
      margin-top: 10px;
      margin-bottom: 5px;
      text-align: center;
      padding: 0 15px;
    }
  }


  .active-font {
    background-color: rgba(#03A786, .75);
    color: $white;
    font-size: 18px;
    padding: 5px 15px;
  }

  .font {
    font-size: 16px;
    margin-bottom: 5px;

    &:hover {
      background-color: rgba(#03A786, .35);
      fill: $white;
      @include media("<wide") {
        background-color: transparent;
      }
    }
  }
}

.designer-right-panel-item__fontType {
  .designer-right-panel-item__titleFlex {
    //padding-right: 0;
    width: 100%;
    //flex-direction: row;
  }
}

.designer-right-panel-item__fontStyle {
  @include media("<=wide") {
    display: none;
  }
}

.designer-right-panel-item__fontTransform {
  flex-direction: column;
  overflow: visible;

  .designer-right-panel-item__titleFlex {
    width: 100%;
  }

  .designer-right-panel-inputNumber {
    width: 100%;
    margin-bottom: 15px;
  }

  @include media("<=wide") {
    display: none;
  }
}

.designer-right-panel-item__deleteBtn,
.designer-right-panel-item_justification,
.designer-right-panel-item_changeSize {
  .designer-right-panel-item__titleFlex {
    padding-left: 19px;
  }
}


.designer-right-panel-item__deleteBtn {
  padding-right: 0;
  transition: background-color .3s ease-in-out;

  &:hover {
    background-color: rgba($warning-col, 0.35);
  }

  .designer-right-panel-item__titleFlex {
    width: 100%;
    justify-content: center;
  }
}

.designer-right-panel-item--admin {
  @include media("<=wide") {
    display: none;
  }
}

.designer-right-panel-item_positions {
  @include media("<=wide") {
    display: none;
  }
  flex-direction: column;

  .designer-right-panel-item__titleFlex {
    width: 100%;
  }

  .designer-right-panel-inputNumber_title {
    display: flex;
    align-items: center;
    padding-left: 5px;
    margin-bottom: 5px;
    justify-content: space-between;
    width: 100%;
    max-width: 140px;
  }
}


.designer-right-panel-item_justification {
  display: flex;
  @include media("<=wide") {
    .designer-right-panel-item__titleFlex {
      padding: 5px;
    }
    flex-direction: column;
    margin: 0 10px;
    .designer-right-panel-btn:nth-child(1) {
      margin-left: 0;
    }
  }
}

.designer-right-panel-item_changeSize {
  display: none;

  button {
    border: 1px solid rgba(#A7AAA8, 1);
    background-color: $white;
    height: 30px;
    padding: 6px;
  }

  @include media(">wide") {
    display: flex;
  }
}

.designer-right-panel-item_changeSize {
  justify-content: flex-start;
}

.designer-right-panel-item_rotate.active .dropdownContent {
  display: flex;
  @include media("<=wide") {
    padding-top: 37px;
  }
}

.designer-rotate-close {
  display: none;
}

.designer-right-panel-item_rotate {
  padding-right: 0;

  .designer-rotate-btns {
    display: flex;

    button {
      height: 35px;
      padding-top: 8px;
    }

    @include media("<=wide") {
      flex-direction: row;
      .designer-rotate-close {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        border: 1px solid rgba($border-col, 1);
        padding: 3px 5px;
        font-family: $font-primary;
      }
    }
  }

  .text {
    padding-right: 28px;
  }

  @include media("<=wide") {
    .text {
      padding-right: 0;
    }
    //display: none;
    //max-width: 100%;
    width: 100%;
    .svg-inline--fa, img {

    }
    .designer-rotate-btns {
      width: 100%;
      display: none;

      .svg-inline--fa, img {
        display: block;
        margin: 0 auto;
      }

      .designer-right-panel-btn {
        &:first-child {
          order: 2;
        }

        width: 50%;
        background-color: transparent;
        border: none;
        font-size: 16px;
      }
    }
  }

  .designer-right-panel-item__titleFlex {
    width: 100%;
  }

  .designer-right-panel-inputNumber {
    padding-left: 0;
    order: 1;
  }


}

.designer-right-panel-item_letterSpacing {

  @include media("<wide") {
    max-width: 145px;
    margin: 0 5px;
    .designer-right-panel-inputNumber input {
      display: none;
    }
    .designer-right-panel-inputNumber {
      margin-left: 0;
      margin-top: -15px;
    }
  }
  flex-wrap: wrap;

  .designer-right-panel-item__titleFlex {
    padding-top: 14px;
    padding-bottom: 14px;
    flex-wrap: wrap;
    width: 100%;
    @include media("<wide") {
      padding: 15px 10px 0 10px;
    }

    img {
      display: none;
    }
  }

  .designer-right-panel-inputNumber {
    display: flex;
    margin-bottom: 10px;
  }
}

.designer-right-panel-item_letterSpacingRange {
  width: 100%;
  max-width: 110px;
  margin-right: 43px;
  @include media("<wide") {
    margin-right: 0;
  }
}

.designer-right-panel-item__checkboxes {
  display: flex;
}


.designer-right-panel-item_fontSize {
  .designer-right-panel-inputNumber {
    display: block;
  }

  @include media("<wide") {
    .designer-right-panel-inputNumber {
      display: none;
    }
    padding-right: 5px;
    width: auto;
  }

  .designer-right-panel-item__titleFlex {
    @include media("<wide") {
      padding-right: 0;
      width: auto;
    }
  }
}

.propMask {
  padding: 15px 10px;

  .form-control {
    margin-top: 5px;
    max-width: 54px;
    margin-right: 4px;
    padding: 3px;
  }

  b {
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
  }
}

.designer-right-panel-item__colorFont {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 616px;

  @include media("<616px") {
    margin-top: 20px;
  }

  .text {
    font-size: 18px;
  }

  .designer-right-panel-item__titleFlex {
    justify-content: flex-start;
    flex-direction: row;
  }

  .arrow {
    transform: rotate(-180deg);
    margin: 0 0 0 15px;
    border: 1px solid #03A786;
    border-radius: 100px;

    &.active {
      transform: rotate(-90deg)
    }
  }
}

.designer-right-panel-item__FontStroke {
  flex-direction: row;
}

.designer-right-panel-item_shadow {
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  padding: 15px 0;
  max-width: 616px;
}

.adminPanel {
  display: none;
  @include media(">wide"){
    position: absolute;
    right: 0;
    top: 0;
    height: 90vh;
    z-index: 2;
    display: block;
  }
}
