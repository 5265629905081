@import "../../assets/scss/index";

.product-preview {
  @include media(">wide") {
    box-shadow: 0 1px 2px 0 rgba(48, 58, 56, .1), 0 8px 16px 0 rgba(40, 52, 49, .1);
  }
  position: absolute;
  bottom: 22px;
  width: 240px;
  margin-left: 10px;
  box-sizing: border-box;
  text-align: center;
  right: 20px;
  @include media("<=wide") {
    display: none;
  }

  &.show {
    @include media(">wide") {
      box-shadow: none;
    }
    display: block;
    background-color: #F3F3F3;

    top: 15px;
    left: 0;
    width: 100%;
    z-index: 3;
    bottom: auto;
    margin-bottom: 22px;
    margin-left: 0;
    min-height: 348px;

    .canvasLoaderPreview {
      background-color: #F3F3F3;

      img {
        border-radius: 0;
      }
    }

    .product-preview-overlay {
      position: fixed;
      top: 0;
      left: 0;
      background-color: $black;
      opacity: 0.2;
      z-index: -1;
      width: 100%;
      height: 100%
    }

    .product-preview__wrap {
      max-width: 95vw;
      max-height: 95vh;
      margin: 0 auto;
      box-shadow: 0 1px 2px 0 rgba(48, 58, 56, .1), 0 8px 16px 0 rgba(40, 52, 49, .1);
      //background-color: $white;
      background-color: #F3F3F3;

      @include media(">=tablet") {
        max-width: 700px;
        max-height: 700px;
      }
    }
  }
}

.product-preview__header {
  display: flex;
  background-color: $white;
  justify-content: space-between;
  min-height: 48px;
  padding: 15px;
  line-height: 30px;
  font-size: 15px;
  font-weight: 600;
  color: $primary;
  text-align: center;

}

.product-preview__img-wrap img {
  width: 100%;
  display: block;
}

.product-preview__btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: $primary;
}

.designer-element-list-trash {
  font-size: 14px;
  margin-right: 10px;

  :hover {
    color: red;
  }
}
