@import "../../assets/scss/index";

.designer-canvas {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 0 10px 10px 10px;
  max-width: 596px;
  max-height: 596px;
  width: calc(100vh - 281px);
  height: calc(100vh - 281px);
  @include media("<=wide") {
    height: calc(100vh - 258px);
  }
  @include media(">tablet") {
    //min-height: calc(100vh - 95px);
  }
}

.canvas {
  overflow: auto;
  color: $black;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 596px;
  max-height: 596px;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-behavior: smooth;
  border: 2px dashed rgba(189, 192, 190, 1);
  @include media(">wide") {
    overflow: auto;

    border-radius: 4px;
    box-shadow: inset 0 0 0 4px rgba(242, 244, 243, 1);
  }
  @include media("<=wide") {
    //max-width: calc(100vh - 281px);
    //max-height: calc(100vh - 281px);
    //width: calc(100vh - 281px);
    //height: calc(100vh - 281px);
    margin: 0 auto;
  }
}


.canvas-wrap {
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}

.canvas-workSpace {
  width: 100%;
  height: 100%;
  cursor: crosshair;
  background-color: $white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  .konvajs-content {
    background-image: url("../../assets/images/canvas-bck.png");
    margin: 0 auto;

    canvas {
      border: 1px solid $border-col !important;
    }
  }
}

.canvas-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.canvas-loader__image {
  width: 300px;
  height: 300px;
  border-radius: 100%;
}

