.canvas-guideline {
  width: 100%;
  //height: 100%;
  position: absolute !important;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 1;
  .konvajs-content {
    margin: 0 auto;
  }
}
.canvas-custom-mask{
  //border: 1px solid black;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide-custom-mask{
  opacity: 0;
}