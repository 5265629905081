.Dropzone {
    margin-top: 20px;
    height: 210px;
    width: 100%;
    text-align: center;
    /*background-color: #fff;*/
    font-size: 16px;
    background-image: url('../../assets/images/upload-image.png');
    background-repeat: no-repeat;
    background-position: center center;

}

.Dropzone .dropzone-info {
    display: block;
    padding-top: 95px;
    color: #ccc;
    font-size: 14px;
}

.DropzoneFaces {
    background: none;
    height: auto;
}

.Dropzone .dropzone-upload-button {
    margin: 10px 0;
}

.Dropzone .dropzone-upload-button-faceUpload  .btn-outline-secondary{
    border-radius: 100px;
    margin: 40px auto;
}

/*.Dropzone .dropzone-upload-button button {*/
/*    border: 1px dashed #868686;*/
/*    background-color: transparent;*/
/*    padding: 11px 45px;*/
/*    border-radius: 4px;*/
/*    cursor: pointer;*/
/*}*/

/*.Dropzone .dropzone-upload-button button:hover {*/
/*    background-color: #e6f5f8;*/
/*    color: #059dc1*/
/*}*/

.Highlight {
    border: 1px dashed #868686;
    background-color: #dbf2ff !important;
}

.Dropzone.Hidden {
    height: 60px;
    background-image: none;
}

.Dropzone.Hidden .dropzone-info {
    display: none;
}

.FileInput {
    display: none;
}

.ProgressBar {
    background-color: #dbf2ff !important;
    width: 85% !important;
    margin-right: 5px;
}

.Progress {
    background-color: #059dc1 !important
}

.Files .Row .Filename {
    font-size: 13px;
}

