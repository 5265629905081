@import "../../assets/scss/index";


.crop__wrap {
  display: flex;
  flex-wrap: wrap;
  //flex-direction: column-reverse;


  &_loading {
    @include media("<wide") {
      display: none;
    }
  }

  @include media(">desktop") {
    flex-direction: row;
  }

  &_avatarEditorLoader {
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_loadingImg {
    background-color: #f3f3f3;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 300px;
      height: 300px;
    }
  }

  &_avatarEditor {
    overflow: hidden;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    position: relative;
    @include media(">desktop") {
      margin-bottom: 25px;
      width: 50%;
    }

    &__heartMask {
      &:before {
        background-size: contain;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.25;
        position: absolute;
        top: 0;
        pointer-events: none;
        background-image: url("../../../src/assets/images/heart_mask.png");
      }
    }

  }


  &_properties {
    width: 100%;
    @include media(">wide") {
      flex: 1 1 auto;
      max-width: 350px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}


.input {
  text-align: center;
  margin: 0 auto 20px auto;
  max-width: 240px;
  display: flex;
  justify-content: center;
  @include media("<375px") {
    max-width: 200px;
    margin: 0 auto 10px auto;
  }


  @include media(">wide") {
    margin: 0 auto 30px auto;
    max-width: 350px;
    width: 100%;
    font-size: 20px;
  }

  &__icon {
    width: 35px;
    height: 35px;
    @include media(">=wide") {
      width: 45px;
      height: 45px;
    }
    @include media("<375px") {
      width: 30px;
      height: 30px;
    }
  }

  &__btn {
    display: inline-block;
    cursor: pointer;
    padding: 0;
    color: #333333;
    border-bottom: 1px solid #333333;
    font-size: 16px;
    line-height: 13px;
    margin-bottom: 10px;

    &_done {
      border-bottom: 1px solid #333333;
    }
  }

  &__title {
    display: block;
    font-weight: 600;
    font-size: 12px;
  }

  &__slider {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    min-width: 150px;

    margin-left: 40px;


    @include media("<375px") {
      min-width: 125px;
    }
    @include media(">=wide") {
      max-width: 196px;
      width: 100%;
    }
  }

  &__sliderLeftBtn,
  &__sliderRightBtn {
    width: 43px;
    height: 43px;

    @include media("<375px") {
      width: 35px;
      height: 35px;
    }
    @include media(">=wide") {
      width: 50px;
      height: 50px;
    }
  }

  &__sliderRightBtn {
    transform: scaleX(-1);
  }
}




