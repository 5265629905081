@import "../../assets/scss/index";

.designer-canvas {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 616px;
  max-height: 616px;
  overflow: auto;
  padding: 0 10px 10px 10px;
  @include media("<=wide") {
    padding: 0;

    &.active {
      display: block;
    }
  }
  @include media(">=wide"){
    margin: 0 30px;
  }
}
 .text-form-popup-content-buttons.text-form-popup-content-buttons_center {
  text-align: center;
}

.canvas {
  overflow: auto;
  color: $black;
  position: relative;
  max-width: 596px;
  max-height: 596px;
  height: calc(100vh - 281px);
  display: flex;
  align-items: center;
  justify-content: center;
  @include media(">wide") {
    border: 2px dashed rgba(189, 192, 190, 1);
    border-radius: 4px;

    box-shadow: inset 0 0 0 4px rgba(242, 244, 243, 1);

  }
  @include media("<=wide") {
    min-height: 320px;
    min-width: 320px;
    width: calc(100vw);
    height: calc(100vw);
    margin: 0 auto;
  }
}


.canvas-wrap {
  height: 100%;
  top: 0;
  left: 0;
}

.canvas-guideline {
  width: 100%;
  height: 100%;
  position: absolute !important;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 1;
  .konvajs-content {
    margin: 0 auto;
  }
}

.canvas-workSpace {
  width: 100%;
  height: 100%;
  cursor: crosshair;
  background-color: $white;
  position: absolute;
  display: flex;
  align-items: center;

  .konvajs-content {
    background-image: url("./../../assets/images/canvas-bck.png");
    margin: 0 auto;

    canvas {
      border: 1px solid $border-col !important;
    }
  }

}

.canvas-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;

  img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
  }
}

.canvas-crop-popup {
  margin: 0 auto;
  @include media("<=tablet") {
    overflow: hidden;
  }
}

.canvas-scale {
  display: none;
  @include media(">wide") {
    border-radius: .35rem;
    background-color: $primary;
    padding: 14px 20px;
    font-family: $font-secondary;
    font-size: 14px;
    color: $white;
    margin: 20px 0;
    display: flex;
    align-items: center;
    //border-radius: 2px;
    //border-radius: .35rem;
  }
}

.canvas-scale__btn {
  background-color: transparent;
  color: $white;
  border: none;
  cursor: pointer;
  min-height: 13px;
  min-width: 13px;
  margin: 0 5px;
}

.canvas-scale__btn_fit {
  margin-left: 30px;
  overflow: hidden;

  img {
    width: 16px;
    height: 16px;
  }
}

.canvas-scale__btn_minus {
  &:after {
    content: "";
    width: 13px;
    height: 1px;
    background-color: $white;
    display: block;
  }
}

//@font-face {
//  font-family: 'Roboto';
//  font-style: italic;
//  font-weight: normal; /* or 400 */
//}


.designer-container .designer-tools-uploader{
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #08BD99;
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  & ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #08BD99;
  }
}
